var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":"","align":"center","justify":"end"}},[(!_vm.getUser.account.role.isSuperAdmin)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item',{on:{"click":_vm.handleOpenViewFeatures}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('VIEW_FEATURES')))])],1),_c('v-list-item',{on:{"click":function($event){_vm.changePasswordDialog = true}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('CHANGE_PASSWORD')))])],1)],1)],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-avatar',{attrs:{"size":"200","color":"primary"}},[_c('v-icon',{attrs:{"size":"60","dark":""}},[_vm._v(" mdi-account ")])],1)],1),_c('div',{staticClass:"d-flex flex-column align-center justify-center mt-3"},[_c('div',{staticClass:"d-flex align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.getUser.account.name)+" ")]),_c('v-chip',{staticClass:"font-weight-bold ml-2",attrs:{"small":"","outlined":"","color":_vm.getRole(_vm.getUser.account.role).color}},[_vm._v(" "+_vm._s(_vm.getRole(_vm.getUser.account.role).description)+" ")])],1),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.getUser.account.email)+" ")])]),_c('v-row',{staticClass:"my-3",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-divider',{staticStyle:{"max-width":"500px"}})],1),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-caption"},on),[_vm._v(" "+_vm._s(_vm.$t('CREATED_AT', { value: _vm.formattedDate(_vm.getUser.account.creationDate) }))+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.formattedDateInWords(_vm.getUser.account.creationDate))+" ")])],1),_c('v-dialog',{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.changePasswordDialog),callback:function ($$v) {_vm.changePasswordDialog=$$v},expression:"changePasswordDialog"}},[(_vm.changePasswordDialog)?_c('v-card',{staticStyle:{"background-color":"var(--v-background-base)"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-app-bar',{attrs:{"dark":"","color":"primary"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},nativeOn:{"click":function($event){_vm.changePasswordDialog = false}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,3440938377)},[_c('span',[_vm._v(_vm._s(_vm.$t('CLOSE')))])]),_c('div',{staticClass:"text-h5 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('CHANGE_PASSWORD'))+" ")])],1),_c('profile-change-password',{on:{"close":function($event){_vm.changePasswordDialog = false}}})],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"700"},model:{value:(_vm.myFeaturesDialog),callback:function ($$v) {_vm.myFeaturesDialog=$$v},expression:"myFeaturesDialog"}},[(_vm.myFeaturesDialog)?_c('v-card',{staticStyle:{"background-color":"var(--v-background-base)"}},[_c('v-card-title',[_vm._v(" Suas permissões ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.featureTableHeaders,"item-key":"id","items":_vm.myFeatures,"items-per-page":5,"footer-props":{
            disableItemsPerPage: true
          }},scopedSlots:_vm._u([{key:"item.canRead",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.canRead ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.canWrite",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.canWrite ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.canUpdate",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.canUpdate ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.canDelete",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.canDelete ? 'mdi-check' : 'mdi-close'))])]}},{key:"item.isActive",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.isActive ? 'mdi-check' : 'mdi-close'))])]}}],null,false,3094828669)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }