<template>
  <v-container fluid>
    <v-row no-gutters align='center' justify='end' class='mt-5'>
      <v-menu
        v-if='!getUser.account.role.isSuperAdmin'
        bottom
        left
        offset-y
      >
        <template #activator='{ on, attrs }'>
          <v-btn
            icon
            v-bind='attrs'
            v-on='on'
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list class='pa-0'>
          <v-list-item @click='handleOpenViewFeatures'>
            <v-list-item-title>{{$t('VIEW_FEATURES')}}</v-list-item-title>
          </v-list-item>
          <v-list-item @click='changePasswordDialog = true'>
            <v-list-item-title>{{$t('CHANGE_PASSWORD')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>

    <v-row no-gutters align='center' justify='center'>
      <v-avatar size='200' color='primary'>
        <v-icon size='60' dark>
          mdi-account
        </v-icon>
      </v-avatar>
    </v-row>

    <div class='d-flex flex-column align-center justify-center mt-3'>
      <div class='d-flex align-center'>
        <h2>
          {{getUser.account.name}}
        </h2>
        <v-chip small outlined :color='getRole(getUser.account.role).color' class='font-weight-bold ml-2'>
          {{getRole(getUser.account.role).description}}
        </v-chip>
      </div>
      <span class='text-caption'>
        {{getUser.account.email}}
      </span>
    </div>

    <v-row no-gutters align='center' justify='center' class='my-3'>
      <v-divider style='max-width: 500px' />
    </v-row>

    <v-row no-gutters align='center' justify='center'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <div class='text-caption' v-on='on'>
            {{$t('CREATED_AT', { value: formattedDate(getUser.account.creationDate) })}}
          </div>
        </template>
        {{formattedDateInWords(getUser.account.creationDate)}}
      </v-tooltip>
    </v-row>

    <!-- ---------------------------------------------------------------- -->
    <!-- DIALOGS -->
    <!-- ---------------------------------------------------------------- -->
    <v-dialog v-model='changePasswordDialog' fullscreen persistent>
      <v-card v-if='changePasswordDialog' style='background-color: var(--v-background-base);'>
        <v-container fluid class='pa-0'>
          <v-app-bar dark color='primary'>
            <v-tooltip bottom>
              <template #activator='{ on }'>
                <v-btn icon v-on='on' @click.native='changePasswordDialog = false'>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{$t('CLOSE')}}</span>
            </v-tooltip>

            <div class='text-h5 ml-2'>
              {{$t('CHANGE_PASSWORD')}}
            </div>
          </v-app-bar>

          <profile-change-password @close='changePasswordDialog = false' />
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model='myFeaturesDialog' width='700'>
      <v-card v-if='myFeaturesDialog' style='background-color: var(--v-background-base);'>
        <v-card-title>
          Suas permissões
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers='featureTableHeaders'
            item-key='id'
            :items='myFeatures'
            :items-per-page='5'
            :footer-props='{
              disableItemsPerPage: true
            }'
          >
            <template #item.canRead='{ item }'>
              <v-icon>{{item.canRead ? 'mdi-check' : 'mdi-close'}}</v-icon>
            </template>

            <template #item.canWrite='{ item }'>
              <v-icon>{{item.canWrite ? 'mdi-check' : 'mdi-close'}}</v-icon>
            </template>

            <template #item.canUpdate='{ item }'>
              <v-icon>{{item.canUpdate ? 'mdi-check' : 'mdi-close'}}</v-icon>
            </template>

            <template #item.canDelete='{ item }'>
              <v-icon>{{item.canDelete ? 'mdi-check' : 'mdi-close'}}</v-icon>
            </template>

            <template #item.isActive='{ item }'>
              <v-icon>{{item.isActive ? 'mdi-check' : 'mdi-close'}}</v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { format, parseISO, formatDistanceToNow } from 'date-fns';
  import ptBr from 'date-fns/locale/pt-BR';
  import axios from 'axios';

  export default {
    name: 'Profile',
    components: {
      ProfileChangePassword: () => import('./ProfileChangePassword'),
    },
    data: function () {
      return {
        changePasswordDialog: false,
        myFeaturesDialog: false,
        myFeatures: [],
        featureTableHeaders: [
          { text: this.$t('NAME'), value: 'name' },
          { text: this.$t('WRITE'), value: 'canWrite' },
          { text: this.$t('UPDATE'), value: 'canUpdate' },
          { text: this.$t('READ'), value: 'canRead' },
          { text: this.$t('DELETE'), value: 'canDelete' },
          { text: this.$t('ACTIVE'), value: 'isActive' },
        ],
      };
    },
    computed: {
      ...mapGetters('auth', [
        'getUser',
      ]),
    },
    methods: {
      handleOpenViewFeatures: function () {
        this.myFeaturesDialog = true;
        this.getMyFeatures();
      },
      formattedDate: function (value) {
        return format(parseISO(value), 'dd/MM/yyyy HH:mm');
      },
      formattedDateInWords: function (value) {
        return formatDistanceToNow(parseISO(value), { locale: ptBr });
      },
      getRole: function (role) {
        if (role.isSuperAdmin) {
          return {
            color: 'error',
            description: this.$t('SUPER_ADMIN'),
          };
        }
        if (role.isAdmin) {
          return {
            color: 'error',
            description: this.$t('ADMIN'),
          };
        }
        return {
          color: 'info',
          description: this.$t('USER'),
        };
      },
      getMyFeatures: async function () {
        try {
          const { data } = await axios({
            url: '/account-get-features',
            baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
            method: 'GET',
            responseType: 'json',
            withCredentials: true,
          });

          this.myFeatures = data && data.list;
        } catch (e) {
          console.log(e); /* eslint-disable-line no-console */
        }
      },
    },
  };
</script>
